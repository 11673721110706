<template>
    <div class="BFmain-light" :class='$mq'></div>
    <div class='BFmain-cont' :class='$mq'>
        <KImage :src="require('@/assets/blackfriday/BF-present.png')" imgClass='BFmain-pres' alt=""/>
        <KImage :src="require('@/assets/blackfriday/BF-confetti.png')" imgClass='BFmain-confet' alt=""/>
        <div class="BFmain-text" :class='$mq'>
            <h1>Скидки <br><span class='script'>до</span><span class='fifty'>-50%</span></h1>

        </div>
        <p class='BFmain-dates' :class='$mq'>с 27 по 29 ноября</p>
        <div class="BFmain-img" :class='$mq'></div>
        <!-- <img class="BFmain-img" src="/img/blackfriday/BF-maindoll.png"/>         -->
        <div class="BFmain-arrow" :class='$mq'>
            <KImage :src="require('@/assets/blackfriday/arrow-down.svg')"  alt=""/>
        </div>
    </div>
</template>

<style>
    .BFmain-cont {
        display: flex;
        max-width: 680px;
        margin: 0 auto 0px auto;
        border: 9px solid #d79f62;
        position: relative;
        z-index: 1;
        padding: 50px 0 80px 100px;
    }

    .BFmain-cont.tablet {
        padding: 30px 0 50px 70px;
    }

    .BFmain-cont.halftablet {
        padding: 30px 0 50px 15%;
    }

    .BFmain-cont.mobile {
        margin-bottom: 0px;
        border: 5px solid #d79f62;
        padding: 20px;
    }

    .BFmain-light {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/blackfriday/BF-light.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        z-index: 5;
    }

    .BFmain-light.mobile, .BFmain-light.halftablet {
        display: none;
    }

    .BFmain-img {
        max-width: 100%;
        width: 660px;
        height: 661px;
        background: url('~@/assets/blackfriday/BF-maindoll.png') no-repeat;
        background-size: cover;
        position: absolute;
        z-index: 1;
        display: inline-block;
        right: -25%;
        top: -130px;;
    }

    .BFmain-img.tablet {
        right: -20%;
    }

    .BFmain-img.halftablet {
        width: 450px;
        height: 500px;
        top: -120px;
        right: -15%;
    }

    .BFmain-img.mobile {
        max-width: 400px;
        height: 400px;
        right: -150px;
        top: -70px;
    }

    .BFmain-text {
        width: 50%;
        color: white;
        position: relative;
        z-index: 2;
    }

    .BFmain-text.mobile {
        margin: 0;
    }

    .BFmain-text.halftablet h1 {
        font-size: 55pt;
        line-height: 76pt;
    }

    .BFmain-text.mobile h1 {
        font-size: 50pt;
        line-height: 70pt;
    }

    .BFmain-text.halftablet span.fifty {
        font-size: 86pt;
    }

    .BFmain-text.mobile span.fifty {
        font-size: 80pt;
    }

    .BFmain-text.halftablet span.script {
        font-size: 40pt;
        left: 0px;
        bottom: 20px;
    }

    .BFmain-text.mobile span.script {
        font-size: 50pt;
        left: 10px;
        bottom: 20px;
    }

    .BFmain-text h1 {
        font-size: 75pt;
        line-height: 105pt;
    }

    .BFmain-text span.fifty {
        font-size: 120pt;
    }

    .BFmain-text span.script {
        position: absolute;
        font-family: 'TheArtist', arial, sans-serif;
        font-size: 90px;
        bottom: 30px;
        left: 0;
    }

    .BFmain-dates {
        display: block;
        position: absolute;
        background-color: #d79f62;
        padding: 7px 30px;
        font-size: 20pt;
        left: -5px;
        bottom: 20px;
    }

    .BFmain-dates.tablet {
        font-size: 18pt;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        bottom: -20px;
    }

    .BFmain-dates.halftablet {
        font-size: 14pt;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        bottom: -20px;
        padding: 7px 15px;
    }

    .BFmain-dates.mobile {
        display: inline-block;
        font-size: 11pt;
        padding: 7px 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        bottom: -20px;
        line-height: 12pt;
    }

    .BFmain-arrow {
        position: absolute;
        bottom: -20px;
        background-color: #17122d;
        padding: 0 15px;
        left: 50%;
        transform: translateX(-50%);
    }

    .BFmain-arrow.tablet {
        display: none;
    }

    .BFmain-arrow.mobile {
        display: none;
    }

    .BFmain-arrow img {
        width: 58px;
    }

    .BFmain-confet, .BFmain-pres {
        position: absolute;
    }

    .BFmain-confet {
        width: 446px;
        top: -80px;
        left: -130px;
        z-index: 0;
    }

    .BFmain-confet.halftablet {
        width: 336px;
        top: 10px;
        left: -150px;
    }

    .BFmain-confet.mobile {
        display: none;
    }

    .BFmain-pres {
        width: 336px;
        top: -140px;
        left: -200px;
        z-index: 1;
    }

    .BFmain-pres.tablet {
        width: 290px;
        left: -150px;
        top: -100px;
    }

    .BFmain-pres.halftablet {
        width: 200px;
        top: -50px;
        left: -100px;
    }

    .BFmain-pres.mobile {
        width: 225px;
        left: 0;
        display: none;
    }

</style>